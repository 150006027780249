import React from 'react';
import Thanks from "./views/thanks/thanks";
import Main from "./views/main/main";
import Verify from "./views/verify/verify";
import BuyBtn from "./views/shopify/buyBtn/btn";
import First from "./views/register/first-step/first";
import SecondStep from "./views/register/second-step/second-step";
const routes =(isLoggedIn) =>[
  {
    path: "/thanks",element: <Thanks></Thanks>,
  },
  {
    path: "/thanks/:ein/:product",element: <Thanks></Thanks>,
  },
  {
    path: "/verify/:userid/:code",element: <Verify></Verify>,
  },
  {
    path: "/:ein/:product" ,element: <First></First>
  },
  {
    path: "/s" ,element: <First></First>
  },
  {
    path: "/complete" ,element: <SecondStep></SecondStep>
  },
  {
    path: "/" ,element: <First></First>
  },
  { path: '*', element: <First/> },

];
//
//   {
//     path: 'app',
//     element: <DashboardLayout />,
//     children: [

//     ]
//   },
//   {
//     path: '/',
//     element: <MainLayout />,
//     children: [
//       { path: 'login', element: <LoginView /> },
//       { path: 'register', element: <RegisterView /> },
//       { path: '404', element: <NotFoundView /> },
//       { path: '/', element: <Navigate to="/app/dashboard" /> },
//       { path: '*', element: <Navigate to="/404" /> }
//     ]
//   }
// ];

export default routes;
