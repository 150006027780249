import React, { useContext, useEffect, useState } from 'react';
import {Divider, Grid, Image} from "semantic-ui-react";
import { Header, Table } from 'semantic-ui-react'
import axios from "../../axios-agent";

import Classes from "../thanks/thanks.module.css"
import {useNavigate, useParams} from "react-router-dom";
import {LinearProgress} from "@material-ui/core";
const Verify = (props) => {
    const { userid,code } = useParams();
    const navigate=useNavigate();

    const [content,setContent]=useState(<LinearProgress />)

    useEffect(()=>{
        console.log(userid)
        const path="/sweep/user/verify/"+userid+"/"+code
        axios.get(path).then(res=>{
            localStorage.setItem("userid",res.data.userid)
            localStorage.setItem("code",code)
            localStorage.setItem("token",res.data.token)
            localStorage.setItem("email",res.data.email)
            navigate("/complete")
        }).catch(err=>{
            localStorage.clear()
            // navigate("/")
        })

    },[])

    return(<div>
        {content}
</div>)
}
export default Verify;