import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import AuthContextProvider from './context/auth-context';
import Client from 'shopify-buy';
const client = Client.buildClient({
    storefrontAccessToken: '85aea687fc01786393dbd97cf3b08828',
    domain: 'sweepsforacause.myshopify.com'});
ReactDOM.render((
  <AuthContextProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </AuthContextProvider>

), document.getElementById('root'));

serviceWorker.unregister();
