import React, { useContext, useEffect, useState } from 'react';
import {Button, Checkbox, Divider, Form, Grid, Icon, Image, Input, Message, Select} from "semantic-ui-react";
import Classes from "../second-step/main.module.css"
import ReCAPTCHA from "react-google-recaptcha";
import {LinearProgress} from "@material-ui/core";
import $ from 'jquery';

import {useNavigate, useParams} from "react-router-dom";
import BuyBtn from "../../shopify/buyBtn/btn";
import Client from "shopify-buy";
import axios from "../../../axios-agent";
const SecondStep = (props) => {
    const { ein,product } = useParams();
    const [productID,setProductID]=useState("")
    const [imageUrl,setImageUrl]=useState("https://res.cloudinary.com/viralsweep/image/upload/w_390,f_auto,dpr_1.3,fl_lossy,c_limit,q_auto/v1642732812/ddwufwb1eihr8tjs1ezl.png")
    const [einNumber,setEinNumber]=useState("")
    const [validProductID,setValid]=useState(false)
    const [title,setTitle]=useState("Win $10,000 Cash + $1K For Your Nonprofit")
    const [des,setDes]=useState("Giving Forward’s primary initiative is in cause marketing powered publishing We are launching several publishing brands / domains GooodBuzz is the launch domain and is a cross between Instagram and Medium, where all ad revenue goes to nonprofits 50+ percent to the nonprofits the viewers and contributors select. We are also launching a multi-nonprofit virtual events platform. The common theme across all of our current and future initiatives is the conversion of " +
        "attention and behavior to revenue for nonprofits through advertising, marketing, commerce and volunteerism.")
    const [content,setContent]=useState(<div>
        <LinearProgress />
    </div>)
    const [emailError,setEmailError]=useState(false)
    const [conditionError,setConditionError]=useState(false)
    const [loading,setLoading]=useState(false)
    const[formError,setFormError]=useState(false)
    const[formSuccess,setFormSuccess]=useState(false)
    const [formErrorMessage,setFormErrorMessage]=useState("")
    const countryOptions = [
        { key: 'usa', value: 'usa', text: 'United States of America' },
        { key: 'af', value: 'af', text: 'Afghanistan' },
        { key: 'ax', value: 'ax', text: 'Aland Islands' },
        { key: 'al', value: 'al', text: 'Albania' },
        { key: 'dz', value: 'dz', text: 'Algeria' },
        { key: 'as', value: 'as', text: 'American Samoa' },
        { key: 'ad', value: 'ad', text: 'Andorra' },
        { key: 'ao', value: 'ao', text: 'Angola' },
        { key: 'ai', value: 'ai', text: 'Anguilla' },
        { key: 'ag', value: 'ag', text: 'Antigua' },
        { key: 'ar', value: 'ar', text: 'Argentina' },
        { key: 'am', value: 'am', text: 'Armenia' },
        { key: 'aw', value: 'aw', text: 'Aruba' },
        { key: 'au', value: 'au', text: 'Australia' },
        { key: 'at', value: 'at', text: 'Austria' },
        { key: 'az', value: 'az', text: 'Azerbaijan' },
        { key: 'bs', value: 'bs', text: 'Bahamas' },
        { key: 'bh', value: 'bh', text: 'Bahrain' },
        { key: 'bd', value: 'bd', text: 'Bangladesh' },
        { key: 'bb', value: 'bb', text: 'Barbados' },
        { key: 'by', value: 'by', text: 'Belarus' },
        { key: 'be', value: 'be', text: 'Belgium' },
        { key: 'bz', value: 'bz', text: 'Belize' },
        { key: 'bj', value: 'bj', text: 'Benin' },
    ];
    const navigate=useNavigate();

    function validateProduct(id) {
        let valid =false
        client.product.fetchAll().then((products) => {
            $.each(products, function(i,item){
                if (item.handle == id) { //4k-fantasy-sweeps ==test-product
                    valid=true
                    setDes(item.attrs.description.value)
                    setTitle(item.attrs.title.value)
                    setImageUrl(item.attrs.images[0].src)
                    setValid(true)
                }
            })
        })

    }
    const [ip, setIP] = useState('');
    //creating function to load ip address from the API
    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        console.log(res.data);
        setIP(res.data.IPv4)
        setValues({
            ...values,
            ['ip']: res.data.IPv4
        });
    }
    useEffect( () => {
        //passing getData method to the lifecycle method
        getData()
        let token=localStorage.getItem("token")
        let email=localStorage.getItem("email")
        console.log(email)
        if (email!==null){
            setValues({
                ...values,
                ['email']: email
            });
        }else {
            localStorage.clear()
            navigate("/")
        }
        let userid=localStorage.getItem("userid")
        if (userid===null || userid===""){
            localStorage.clear()
            navigate("/")

        }else {
            const path="/sweep/user/"+userid
            setContent(<div>
                <LinearProgress />
            </div>)
            axios.defaults.headers.common['Authorization'] = "Bearer "+token
            axios.get(path).then(res=>{
                console.log(res.data)

            }).catch(err=>{
                localStorage.clear()
                navigate("/")

            })
        }


    }, [])
    function getProductAndEIN() {
        let einInStorage=""
        let productInStorage=""
        if (ein==undefined){
            if(localStorage.getItem("ein")!==undefined && localStorage.getItem("ein")!=""){
                einInStorage=localStorage.getItem("ein");
                productInStorage=localStorage.getItem("product");
            }else {
                einInStorage="842933199";
                productInStorage="win-4-000-in-crypto-plus-1k-for-your-nonprofit";
            }

        }else {
            einInStorage=ein
            productInStorage=product
        }
        return [einInStorage,productInStorage]

    }

    var client = Client.buildClient({
        domain: 'sweepsforacause.myshopify.com',
        storefrontAccessToken: '85aea687fc01786393dbd97cf3b08828',
    });


    const states=[
        {
            "text": "Alabama",
            "value": "AL",
            "key": "AL"
        },
        {
            "text": "Alaska",
            "value": "AK",
            "key": "AK"
        },
        {
            "text": "Arizona",
            "value": "AZ",
            "key": "AZ"
        },
        {
            "text": "Arkansas",
            "value": "AR",
            "key": "AR"
        },
        {
            "text": "California",
            "value": "CA",
            "key": "CA"
        },
        {
            "text": "Colorado",
            "value": "CO",
            "key": "CO"
        },
        {
            "text": "Connecticut",
            "value": "CT",
            "key": "CT"
        },
        {
            "text": "Delaware",
            "value": "DE",
            "key": "DE"
        },
        {
            "text": "Florida",
            "value": "FL",
            "key": "FL"
        },
        {
            "text": "Georgia",
            "value": "GA",
            "key": "GA"
        },
        {
            "text": "Hawaii",
            "value": "HI",
            "key": "HI"
        },
        {
            "text": "Idaho",
            "value": "ID",
            "key": "ID"
        },
        {
            "text": "Illinois",
            "value": "IL",
            "key": "IL"
        },
        {
            "text": "Indiana",
            "value": "IN",
            "key": "IN"
        },
        {
            "text": "Iowa",
            "value": "IA",
            "key": "IA"
        },
        {
            "text": "Kansas",
            "value": "KS",
            "key": "KS"
        },
        {
            "text": "Kentucky",
            "value": "KY",
            "key": "KY"
        },
        {
            "text": "Louisiana",
            "value": "LA",
            "key": "LA"
        },
        {
            "text": "Maine",
            "value": "ME",
            "key": "ME"
        },
        {
            "text": "Maryland",
            "value": "MD",
            "key": "MD"
        },
        {
            "text": "Massachusetts",
            "value": "MA",
            "key": "MA"
        },
        {
            "text": "Michigan",
            "value": "MI",
            "key": "MI"
        },
        {
            "text": "Minnesota",
            "value": "MN",
            "key": "MN"
        },
        {
            "text": "Mississippi",
            "value": "MS",
            "key": "MS"
        },
        {
            "text": "Missouri",
            "value": "MO",
            "key": "MO"
        },
        {
            "text": "Montana",
            "value": "MT",
            "key": "MT"
        },
        {
            "text": "Nebraska",
            "value": "NE",
            "key": "NE"
        },
        {
            "text": "Nevada",
            "value": "NV",
            "key": "NV"
        },
        {
            "text": "New Hampshire",
            "value": "NH",
            "key": "NH"
        },
        {
            "text": "New Jersey",
            "value": "NJ",
            "key": "NJ"
        },
        {
            "text": "New Mexico",
            "value": "NM",
            "key": "NM"
        },
        {
            "text": "New York",
            "value": "NY",
            "key": "NY"
        },
        {
            "text": "North Carolina",
            "value": "NC",
            "key": "NC"
        },
        {
            "text": "North Dakota",
            "value": "ND",
            "key": "ND"
        },
        {
            "text": "Ohio",
            "value": "OH",
            "key": "OH"
        },
        {
            "text": "Oklahoma",
            "value": "OK",
            "key": "OK"
        },
        {
            "text": "Oregon",
            "value": "OR",
            "key": "OR"
        },
        {
            "text": "Pennsylvania",
            "value": "PA",
            "key": "PA"
        },
        {
            "text": "Rhode Island",
            "value": "RI",
            "key": "RI"
        },
        {
            "text": "South Carolina",
            "value": "SC",
            "key": "SC"
        },
        {
            "text": "South Dakota",
            "value": "SD",
            "key": "SD"
        },
        {
            "text": "Tennessee",
            "value": "TN",
            "key": "TN"
        },
        {
            "text": "Texas",
            "value": "TX",
            "key": "TX"
        },
        {
            "text": "Utah",
            "value": "UT",
            "key": "UT"
        },
        {
            "text": "Vermont",
            "value": "VT",
            "key": "VT"
        },
        {
            "text": "Virginia",
            "value": "VA",
            "key": "VA"
        },
        {
            "text": "Washington",
            "value": "WA",
            "key": "WA"
        },
        {
            "text": "West Virginia",
            "value": "WV",
            "key": "WV"
        },
        {
            "text": "Wisconsin",
            "value": "WI",
            "key": "WI"
        },
        {
            "text": "Wyoming",
            "value": "WY",
            "key": "WY"
        }
    ];
    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        state: '',
        country: '',
        address:'',
        postcode:'',
        ip:'',
    });
    useEffect(()=>{
        const userid=localStorage.getItem('userid')
        const token=localStorage.getItem("token")
        if ((userid==null) || (token==null)){
            navigate("/")
        }
        const path="/sweep/user/"+userid
        setContent(<div>
            <LinearProgress />
        </div>)
        axios.defaults.headers.common['Authorization'] = "Bearer "+token
        axios.get(path).then(res=>{
            console.log(res.data)
            // setUserInfo(res.data.userInfo)
        }).catch(err=>{
            // localStorage.clear()
        })

    },[validProductID])

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    const countryChange = (event) => {
        setValues({
            ...values,
            ['country']: event.target.outerText
        });
    };
    const stateChange = (event) => {
        setValues({
            ...values,
            ['state']: event.target.outerText
        });
    };
    const handleChange = (event) => {
        setFormError(false)
        setConditionError(false)
        setEmailError(false)
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };
    const onChangeCaptcha=(value)=>{
        const form_data={
            token:value
        }
        axios.post("/captcha", form_data)
            .then(res => {
                setFormError(false)

            })
            .catch(err => {
                    setFormErrorMessage("You Should Check Recaptcha first.")
                    setFormError(true)
                }
            )
    };
    const agreement="I agree to the RULES and Yes, I'd like to receive exclusive emails from Giving Forward / SweepsForACause and other partners via email. Where partner is the nonprofit selected if other than GF."

    function registerHandler() {
       let  email=localStorage.getItem("email")
        if (email===null){
            setFormError("No Email")
            navigate("/")
        }
        values.email=email;
        console.log(values)
            setLoading(true)
            axios.post("/sweep/user", values)
                .then(res => {
                    setFormError(false)
                    setFormSuccess(true)
                    setLoading(false)
                    navigate("/thanks")
                })
                .catch(err => {
                        setLoading(false)
                        setFormSuccess(false)
                        setFormErrorMessage(err)
                        setFormError(true)
                    }
                )

    }

    useEffect(()=>{
        setContent(
            <div className={Classes.center}>
                <Grid  celled='internally'>

                    <Grid.Row>
                        <Grid.Column mobile={16} tablet={8} computer={4} >
                            <Grid.Row >
                                <Image src={imageUrl}></Image>
                                <Divider></Divider>
                            </Grid.Row>
                            <Grid.Row>
                                <div className={Classes.donate}><BuyBtn einNumber={einNumber} productID={productID}></BuyBtn></div>

                                {/*<div dangerouslySetInnerHTML={{ __html: htmlContent }} ></div>*/}
                                {/*<Button className={Classes.btn}>Salam</Button>*/}
                            </Grid.Row>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <div className={Classes.logo}>
                                <Grid.Row>
                                    <Image src='https://res.cloudinary.com/viralsweep/image/upload/f_auto,dpr_auto,fl_lossy,c_limit,q_auto/v1639205894/g2yciqenapx1wvfzpuzu.png' size='large' />
                                </Grid.Row>
                            </div>
                            <Divider></Divider>
                            <Grid.Row>
                                <h3> {title}</h3>
                                <p>
                                    {des}
                                </p>
                            </Grid.Row>
                            <Divider />
                            <Grid.Row>

                                <Form error={formError} success={formSuccess}>
                                    {/*<Form.Field>*/}
                                    {/*    <Form.Input*/}
                                    {/*        error={emailError}*/}
                                    {/*        placeholder='Email' name="email" onChange={handleChange}>*/}
                                    {/*        <input />*/}
                                    {/*    </Form.Input>*/}
                                    {/*</Form.Field>*/}
                                    <Form.Group widths='equal'>
                                        <Form.Input fluid placeholder='First name' name="firstname" onChange={handleChange} />
                                        <Form.Input fluid  placeholder='Last name' name="lastname" onChange={handleChange}/>
                                    </Form.Group>
                                    <Form.Field>
                                        <Select placeholder='Select your country' options={countryOptions} onChange={countryChange}  />
                                    </Form.Field>
                                    <Form.Field>
                                        <Select placeholder='Select your State' options={states}  onChange={stateChange}/>
                                    </Form.Field>

                                    <Form.Group widths='equal'>
                                        <Form.Input icon='envelope outline' iconPosition='left' fluid placeholder='Postal Code' name="postcode" onChange={handleChange} />
                                        <Form.Input fluid icon='phone' iconPosition='left' placeholder='Phone' name="phone" onChange={handleChange} />
                                    </Form.Group>
                                    <Form.Input  icon="address card outline"  iconPosition='left'  fluid placeholder='Address' name="address"  onChange={handleChange} />
                                    {/*<Form.Field>*/}
                                    {/*    <ReCAPTCHA*/}
                                    {/*        sitekey="6LcCSN4UAAAAAEE0bX4d3GSFaZ7z3qVANzgEIwV5"*/}
                                    {/*        onChange={onChangeCaptcha}*/}
                                    {/*    />*/}
                                    {/*</Form.Field>*/}
                                    {/*<Form.Field>*/}
                                    {/*    <Form.Checkbox*/}
                                    {/*        onClick={()=>{*/}
                                    {/*            setConditionError(false)*/}
                                    {/*            let current = agreementCondition;*/}
                                    {/*            setAgreementCondition(!current)*/}
                                    {/*        }}*/}
                                    {/*        label={agreement}*/}
                                    {/*        error={conditionError}/>*/}
                                    {/*</Form.Field>*/}
                                    <Message
                                        hidden={!formError}
                                        error={formError}
                                        header='Error'
                                        content={formErrorMessage}
                                    />
                                    {/*<Message*/}
                                    {/*    hidden={!formSuccess}*/}
                                    {/*    success={formSuccess}*/}
                                    {/*    header='Success'*/}
                                    {/*    content="You registered successfully, Please check your email."*/}
                                    {/*/>                {/*<Message*/}
                                    {/*    hidden={!formSuccess}*/}
                                    {/*    success={formSuccess}*/}
                                    {/*    header='Success'*/}
                                    {/*    content="You registered successfully, Please check your email."*/}
                                    {/*/>*/}
                                    {(loading ?<LinearProgress />: null)}
                                    {(loading ?<br></br>: null)}

                                    <Button  disabled={loading} size="large" basic color='green' onClick={registerHandler}>Complete Registration</Button>
                                </Form  >

                            </Grid.Row>
                        </Grid.Column>
                        {/*<Grid.Column width={3}>*/}
                        {/*</Grid.Column>*/}
                    </Grid.Row>
                </Grid>
            </div>
        )
    },[validProductID,imageUrl,des,title,values,emailError,conditionError,formError,loading,formSuccess,einNumber,productID])



    return (
        <div>
            {content}
        </div>
    );
}
export default SecondStep;