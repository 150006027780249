import React, { useContext, useEffect, useState } from 'react';
import {Button, Divider, Grid, Image} from "semantic-ui-react";
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { Header, Table } from 'semantic-ui-react'
import Client from 'shopify-buy';
import $ from 'jquery';
import {useNavigate} from "react-router-dom";
import {LinearProgress} from "@material-ui/core";
// https://www.npmjs.com/package/@shopify/buy-button-js
const BuyBtn = (props) => {
    const [content,setContent]=useState(<div>Loading...</div>)
    const [variantId,setVariantId]=useState("Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MTc4MjI0ODE3Nzg0OQ==")
    const [ein,setEIN] = useState("842933199")
    const [productID,setProductID]=useState("win-4-000-in-crypto-plus-1k-for-your-nonprofit")
    var client = Client.buildClient({
        domain: 'sweepsforacause.myshopify.com',
        storefrontAccessToken: '85aea687fc01786393dbd97cf3b08828',
    });
    useEffect(()=>{

            if(props.productID!==undefined && props.productID!=null){
                setProductID(props.productID)
            }else {
            }
            if(props.einNumber!==undefined && props.einNumber!=null){
                setEIN(props.einNumber)
            }



    },[props.einNumber,props.productID])

    useEffect(()=>{
        setContent(
            <Button onClick={checkout_with_variant} id="two_dollar_button" basic size='huge' color='green'>
                 Or Donate Only 2$
        </Button>
        )

    },[variantId])
    function checkout_with_variant() {

        client.checkout.create().then((checkout) => {
            const checkoutId = checkout.id
            const input = {customAttributes: [{key: "EIN", value: ein}]};
            client.checkout.updateAttributes(checkoutId, input).then((checkout) => {

                const lineItemsToAdd = [
                    {
                        variantId: variantId,
                        quantity: 1
                    }
                ];
                client.checkout.addLineItems(checkoutId, lineItemsToAdd).then((checkout) => {
                    document.location = checkout.webUrl;
                });

            });

        });

    }

    function get_product_and_2_dollar_variant() {

        client.product.fetchAll().then((products) => {
            $.each(products, function(i,item){
                if (item.handle == productID) { //4k-fantasy-sweeps ==test-product
                    console.log(item.attrs.title)
                    console.log(item.attrs)

                    let product = item;
                    $.each(product.variants, function(i,variant){
                        if (variant.price == "2.00") { // "2.00"
                            let vID=base64_encode(variant.id);
                            setVariantId(vID)
                        }
                    })
                }
            })
        })

    }

    useEffect(()=>{
        get_product_and_2_dollar_variant()
    },[productID])
    return (
        <div>
            {content}
        </div>
    );
}
export default BuyBtn;