import React, { useContext, useEffect, useState } from 'react';
import {Divider, Grid, Image} from "semantic-ui-react";
import { Header, Table } from 'semantic-ui-react'
import axios from "../../axios-agent";
import $ from 'jquery';

import Classes from "../thanks/thanks.module.css"
import {useNavigate, useParams} from "react-router-dom";
import {LinearProgress} from "@material-ui/core";
import BuyBtn from "../shopify/buyBtn/btn";
import {encode as base64_encode} from "base-64";
import Client from "shopify-buy";
const Thanks = (props) => {
    const { ein,product } = useParams();
    const [validProductID,setValid]=useState(false)
    const [imageUrl,setImageUrl]=useState("https://cdn.shopify.com/s/files/1/0600/3389/2537/products/woman-jewels_750x.jpg?v=1642142395%20750w,%20//cdn.shopify.com/s/files/1/0600/3389/2537/products/woman-jewels_900x.jpg?v=1642142395%20900w,%20//cdn.shopify.com/s/files/1/0600/3389/2537/products/woman-jewels_1080x.jpg?v=1642142395%201080w")
    const [productID,setProductID]=useState("")
    const [einNumber,setEinNumber]=useState("")
    const [title,setTitle]=useState("Win $10,000 Cash + $1K For Your Nonprofit")
    const [des,setDes]=useState("Giving Forward’s primary initiative is in cause marketing powered publishing We are launching several publishing brands / domains GooodBuzz is the launch domain and is a cross between Instagram and Medium, where all ad revenue goes to nonprofits 50+ percent to the nonprofits the viewers and contributors select. We are also launching a multi-nonprofit virtual events platform. The common theme across all of our current and future initiatives is the conversion of " +
        "attention and behavior to revenue for nonprofits through advertising, marketing, commerce and volunteerism.")

    const [content,setContent]=useState(<div>Loading...</div>)
    const [authunticate,setAuthunticate]=useState(false)
    const [userEntries,setUseEntries]=useState([])
    const navigate=useNavigate();
    const [userInfo,setUserInfo]=useState({email:""})
    const [amount,setAmount]=useState(0)
    function getProductAndEIN() {
        let einInStorage=""
        let productInStorage=""
        if (ein==undefined){
            einInStorage=localStorage.getItem("ein");
            productInStorage=localStorage.getItem("product");
        }else {
            einInStorage=ein
            productInStorage=product
        }
        return [einInStorage,productInStorage]

    }
    var client = Client.buildClient({
        domain: 'sweepsforacause.myshopify.com',
        storefrontAccessToken: '85aea687fc01786393dbd97cf3b08828',
    });
    function getProductTitle() {

        client.product.fetchAll().then((products) => {
            $.each(products, function(i,item){
                if (item.handle == productID) { //4k-fantasy-sweeps ==test-product

                    setTitle(item.attrs.title)
                    setDes(item.attrs.description.value)


                }
            })
        })

    }
    useEffect(()=>{
        if(validProductID){
            setProductID(localStorage.getItem("product"))
        }
    },[validProductID])
    function validateProduct(id) {
        let valid =false
        client.product.fetchAll().then((products) => {
            $.each(products, function(i,item){
                if (item.handle == id) { //4k-fantasy-sweeps ==test-product
                    valid=true
                    setDes(item.attrs.description.value)
                    setTitle(item.attrs.title.value)
                    setImageUrl(item.attrs.images[0].src)
                    setValid(true)
                }
            })
        })

    }
    useEffect(()=>{


    },[validProductID])
    useEffect(()=>{
        const token= localStorage.getItem("token");
        const userid= localStorage.getItem("userid");
        let einFound =localStorage.getItem("ein");
        let productNumber=localStorage.getItem("product");
        validateProduct(productNumber)

        if(!isNaN(einFound)){
            localStorage.setItem("ein",einFound)
            setEinNumber(einFound)
        }

        if (userid===null || userid===""){
                localStorage.clear()
                navigate("/")
        }else {
            const path="/sweep/user/"+userid
            setContent(<div>
                <LinearProgress />
            </div>)
            axios.defaults.headers.common['Authorization'] = "Bearer "+token
            axios.get(path).then(res=>{
                console.log(res.data)
                setUserInfo(res.data.userInfo)
                setUseEntries(res.data.userEntry.entries)
                setAmount(res.data.userEntry.amount)
                setAuthunticate(true)
            }).catch(err=>{
                // localStorage.clear()
                navigate("/")

            })
        }



    },[])
    useEffect(()=>{
        console.log(productID)
        if (authunticate){
            setContent(
                <div className={Classes.center}>
                    <Grid celled>
                        <Grid.Row>
                            <Grid.Column mobile={16} tablet={8} computer={5}>
                                <Grid.Row>
                                    <Image src={imageUrl}></Image>
                                    <Divider></Divider>
                                    <div className={Classes.center}>
                                        <BuyBtn einNumber={einNumber} productID={productID}></BuyBtn>
                                    </div>
                                </Grid.Row>
                            </Grid.Column>
                            <Grid.Column width={10}>
                                <div className={Classes.logo}>
                                    <Grid.Row>
                                        <Image src='https://res.cloudinary.com/viralsweep/image/upload/f_auto,dpr_auto,fl_lossy,c_limit,q_auto/v1639205894/g2yciqenapx1wvfzpuzu.png' size='large' />
                                    </Grid.Row>
                                </div>
                                <div >
                                    <h1>Thanks for joining us</h1>
                                    <h2>{title}</h2>
                                    <h3> {userInfo.firstname}</h3>
                                    <h5>Your email is: {userInfo.email}</h5>
                                    <p className={Classes.p}>
                                        {des}
                                            </p>
                                    <div className={Classes.center}>
                                        <h3 >Your Current Entries</h3>

                                        <Table basic='very' celled collapsing cla>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>Reason</Table.HeaderCell>
                                                    <Table.HeaderCell>Entries</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>

                                                {userEntries.map(entry=>
                                                    <Table.Row>
                                                        <Table.Cell>
                                                            <Header as='h4' image>
                                                                <Header.Content>
                                                                    {entry.entryType.name}
                                                                    {/*<Header.Subheader>{entry.entryType.name}</Header.Subheader>*/}
                                                                </Header.Content>
                                                            </Header>
                                                        </Table.Cell>
                                                        <Table.Cell>{entry.entryType.amount}</Table.Cell>
                                                    </Table.Row>
                                                )}

                                            </Table.Body>
                                        </Table>
                                        <h5 >Amount : {amount}</h5>

                                    </div>

                                </div>
                            </Grid.Column>

                        </Grid.Row>
                    </Grid>
                </div>)
        }
    },[validProductID,imageUrl,des,title,einNumber,productID,authunticate,userInfo,userEntries,amount])
    return (
        <div>
            {content}
        </div>
    );
}
export default Thanks;